
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";
import moment from "moment";

type Resource = {
  contentable: {
    days: number[];
  };
};

@Component({
  components: {
    datepicker_: () => import("@/app/modules/service/builder/elements/DatePicker.vue"),
    timepicker_: () => import("@/app/modules/service/builder/elements/TimePicker.vue")
  },
  directives: {
    mask
  }
})
export default class ReservationCreateForm extends Vue {
  @Prop(Object) readonly resource!: Resource;
  @Model("input", { type: Object }) readonly formData!: object;

  @Emit()
  input() {
    return this.localFormData;
  }

  @Watch("localFormData", { deep: true })
  onLocalFormDataChanged() {
    this.input();
  }

  localFormData!: object;

  dateMin = moment().format("YYYY-MM-DD");
  valid: boolean = true as boolean;

  rules = {
    required: Rules.required
  };

  constructor() {
    super();

    this.localFormData = { ...this.formData };

    if (Object.keys(this.$route.query).length != 0) {
      const hour = this.$route.query.hour;
      const formattedHour = String(hour).padStart(2, "0");

      this.localFormData["date_from"] = this.$route.query.date;
      this.localFormData["time_from"] = formattedHour + ":00";
    }
  }

  mounted() {
    setTimeout(() => {
      (this.$refs.formRef as Vue & { validate: () => void }).validate();
    }, 100);
  }

  allowedDates(value: string) {
    return this.resource.contentable.days.includes(moment(value).day());
  }
}
